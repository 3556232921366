import { Grid, makeStyles, Typography, useTheme } from "@material-ui/core"
import { graphql } from "gatsby"
import React, { ReactElement } from "react"

import HoverImg from "../../../components/HoverImg"
import { Layout } from "../../../components/Layout"
import { Link } from "../../../components/Link"

const useStyles = makeStyles((theme) => ({
  overlay: {
    left: "50%",
    position: "absolute",
    textAlign: "center",
    top: "50%",
    transform: "translate(-50%, -50%)",
    whiteSpace: "nowrap",
    zIndex: 1,
  },
  trend: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-end",

    "& h2": {
      flexGrow: 1,
    },
  },
}))

interface Props {
  data: any
  location: Location
  pageContext: any
}

function IndexPage(props: Props): ReactElement {
  const classes = useStyles(props)
  const theme = useTheme()
  const { data, location, pageContext } = props
  pageContext.title = "Frühlingstrends 2020"
  const springTrends = [
    {
      image: data.flechtmuster,
      name: `Flechtmuster`,
      path: `/blog/fruehlingstrends-2020/flechtmuster/`,
    },
    {
      image: data.hellblaueTaschen,
      name: `Hellblaue Taschen`,
      path: `/blog/fruehlingstrends-2020/hellblaue-taschen/`,
    },
    {
      image: data.xxlHobos,
      name: `XXL-Hobos`,
      path: `/blog/fruehlingstrends-2020/xxl-hobos/`,
    },
  ]
  pageContext.title = "Frühlingstrends 2020"
  return (
    <Layout location={location} pageContext={pageContext}>
      <Grid container={true} spacing={4}>
        <Grid item={true} xs={12}>
          <Typography align="center" variant="h1">
            {pageContext.title}
          </Typography>
        </Grid>

        <Grid item={true} xs={12}>
          <Grid container={true} spacing={1}>
            {springTrends.map((trend, index) => (
              <Grid item={true} key={index} xs={12} sm={4}>
                <Link href={trend.path} className={classes.trend}>
                  <Typography align="center" variant="h2">
                    {trend.name}
                  </Typography>
                  <HoverImg
                    alt={trend.name}
                    backgroundColor={theme.palette.grey[200]}
                    fluid={trend.image.childImageSharp.fluid}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    flechtmuster: file(relativePath: { eq: "flechtmuster.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 390) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    hellblaueTaschen: file(relativePath: { eq: "hellblaue-taschen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 390) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    xxlHobos: file(relativePath: { eq: "xxl-hobos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 390) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
